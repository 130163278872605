html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #fff;
  text-decoration: none;

  &:visited, &:focus {
    color: #fff;
  }
}

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 35px 15px;
}

.title {
  margin: 0 auto;
  font-size: 40px;
  text-align: center;
  max-width: 620px;
  padding-top: 0;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    padding-top: 0;
    font-size: 28px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.header {
  background-color: #232323;
  padding: 10px 15px 10px 0;
  color: #fff;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .logo {
    max-height: 175px;

    @media (max-width: 767px) {
      max-height: 100px;
    }
  }

  .tel {
    margin-bottom: 16px;
  }

  .info {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    img, svg {
      margin-right: 10px;
    }
  }

  .contacts-info {
    display: flex;
    align-items: flex-start;
    font-size: 18px;

    @media (max-width: 1023px) {
      flex-direction: column;
      font-size: 16px;
    }
  }

  .header-contacts {
    margin-left: 50px;

    @media (max-width: 1023px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .working-time {
    align-items: flex-start;
  }
}

.navigation-container {
  margin: 15px auto;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.navigation-item {
  text-transform: uppercase;

  + .navigation-item {
    margin-left: 20px;
  }

  a.active {
    color: #a1fdc7;
  }
}

.intro, .intro-tuning {
  color: #a1fdc7;
  min-height: 450px;

  @media (max-width: 767px) {
    min-height: 250px;
  }

  .content {
    text-align: center;
  }

  .backdrop {
    background: url('assets/intro.jpg') rgba(0, 0, 0, 0.6);
    background-size: cover;
    background-blend-mode: multiply;
    background-position: 59% 0;
    background-repeat: no-repeat;
    min-height: 450px;

    @media (max-width: 767px) {
      min-height: 250px;
    }

    .title {
      padding-top: 45px;

      @media (max-width: 1023px) {
        padding-top: 0;
      }
    }
  }
}

.intro-tuning {
  .backdrop {
    background: rgba(0, 0, 0, 0.6) url('assets/tuning-intro.jpg') 25% 55%;
  }

  @supports (background: url('assets/tuning-intro.webp') rgba(0, 0, 0, 0.6)) {
    .backdrop {
      background: url('assets/tuning-intro.webp') rgba(0, 0, 0, 0.6) 25% 55%;
    }
  }
}

.cta-button {
  cursor: pointer;
  border: 1px solid #a1fdc7;
  outline: none;
  text-transform: uppercase;
  font-size: 32px;
  min-width: 300px;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #232323;
  color: #a1fdc7;
  margin-top: 100px;

  @media (max-width: 767px) {
    font-size: 24px;
    min-width: 240px;
    padding: 10px;
    margin-top: 70px;
  }

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(72, 72, 72, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-body {
  background-color: rgb(12 12 12 / 95%);
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 0 2px 18px -6px rgb(255, 255, 255);
}

.modal-close-button {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: no-repeat;
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: bold;
}

.modal-button {
  cursor: pointer;
  border: 1px solid #a1fdc7;
  outline: none;
  text-transform: uppercase;
  font-size: 18px;
  min-width: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: #232323;
  color: #a1fdc7;
}

.form-control {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-bottom: 15px;

  label {
    padding-bottom: 5px;
  }

  input, textarea {
    border: 1px solid #232323;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    font-size: 18px;
  }

  .form-error {
    font-size: 16px;
    color: #e10606;
  }
}

.success-message-wrapper {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
}

.how-we-work {
  border-top: 1px solid #a1fdc7;
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0 0, 5px 5px, 0 0;
  color: #a1fdc7;
}

.schema-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  svg {
    width: 81px;
    height: 81px;
    fill: #fff;
  }

  .clear {
    fill: none;
  }
}

.schema-item {
  width: 150px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  box-shadow: 2px 4px 18px -8px rgb(255, 255, 255);
  font-weight: 600;

  @media (max-width: 1023px) {
    margin: 10px;
  }

  @media (max-width: 767px) {
    height: 150px;
  }
}

.tuning-advantages {
  .schema-item {
    width: 200px;
  }
}

.schema-arrow {
  width: 24px;

  @media (max-width: 1120px) {
    display: none;
  }
}

.how-we-work-second-title {
  margin-top: 35px;

  @media (max-width: 1023px) {
    margin-top: 15px;
  }
}

.advantages {
  border-top: 1px solid #a1fdc7;
  color: #a1fdc7;
  background: url('assets/advantages.jpg');

  .advantages-button-wrapper {
    text-align: center;
  }

  .cta-button {
    margin: 20px auto 40px;
  }

  .schema-item {
    text-transform: uppercase;
    font-size: 18px;
    width: 200px;
    background: #49c37b;
    color: #232323;
    border-color: #fff;
    margin: 10px;
  }
}

.contacts {
  border-top: 1px solid #a1fdc7;
  background-color: rgb(32, 32, 32);
  background-image: linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(45deg, black 25%, transparent 25%, transparent 75%, black 75%, black), linear-gradient(to bottom, rgb(8, 8, 8), rgb(32, 32, 32));
  background-size: 10px 10px, 10px 10px, 10px 5px;
  background-position: 0 0, 5px 5px, 0 0;
  color: #a1fdc7;
}

.contacts-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
}

.contacts-info-wrapper {
  flex-basis: 50%;

  @media (max-width: 1023px) {
    flex-basis: 100%;
  }
}

.requisites-title {
  text-transform: uppercase;
  margin-top: 40px;
}

.contacts-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  fill: #fff;
  color: #fff;

  @media (max-width: 1023px) {
    margin-bottom: 30px;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 18px;

    + li {
      margin-top: 15px;
    }
  }

  .info {
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    img, svg {
      margin-right: 10px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  img, svg {
    margin-right: 10px;
  }

  &.requisites-list {
    .info {
      @media (max-width: 767px) {
        display: block;
      }
    }

    li {
      @media (max-width: 767px) {
        display: block;
      }
    }

    span {
      @media (max-width: 767px) {
        display: block;
        margin-top: 5px;
      }
    }
  }
}

.contacts-map {
  flex-basis: 50%;

  @media (max-width: 1023px) {
    flex-basis: 100%;
  }

  iframe {
    width: 100%;
  }
}

.footer {
  border-top: 1px solid #a1fdc7;
  background: #000;
  color: #fff;
  text-align: center;

  .content {
    padding: 15px;
  }
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.image-item {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.slider-wrapper {
  position: relative;
  background-color: rgba(0,0,0,0.2);
  border-radius: 4px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 20px;
  background: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  z-index: 9999;
}

.close-button:hover {
  background: #ddd;
}
